import React, { Component } from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Layout from "../layouts";
import HalfHeroImage from "../components/halfheroimage";
import { Helmet } from "react-helmet";
import { rhythm } from "../utils/typography";
import {
  // Padded,
  GridBox,
  PostMain,
  PostTitleItems,
  MarginTopPost,
  GridHeader,
  GridSectionHeader,
  GridBoxContainer,
  PageSpacing,
  PaddedMobile,
} from "../utils/styles";
import { Flex, Box } from "@rebass/grid/emotion"; //https://github.com/rebassjs/grid
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
import Img from "gatsby-image";

class CompetencyTemplate extends Component {
  render() {
    const competency = this.props.data.wordpressWpCompetency;
    const posts = this.props.data.allWordpressPost;

    return (
      <Layout>
        <Helmet
          title={`MA User Experience Design | Competency > ${competency.title}`}
          meta={[{ name: "description", content: competency.content }]}
        />
        {competency.featured_media &&
          competency.featured_media.localFile &&
          competency.featured_media.localFile.childImageSharp && (
            <HalfHeroImage
              image={competency.featured_media.localFile.childImageSharp.fluid}
            />
          )}
        <PostMain css={MarginTopPost}>
          <Flex
            // mx={[0, -1, -2]}
            // mx={[-1, -1, -2]}
            flexWrap="wrap"
            css={[
              PageSpacing,
              PaddedMobile,
              css`
                margin-top: ${rhythm(2)};
              `,
            ]}
          >
            <Box width={[1, 1 / 2]} px={[1, 1, 2]}>
              <PostTitleItems>
                <h1 dangerouslySetInnerHTML={{ __html: competency.title }} />
              </PostTitleItems>
            </Box>
            <Box width={[1, 1 / 2]} px={[1, 1, 2]}>
              <div dangerouslySetInnerHTML={{ __html: competency.content }} />
            </Box>
          </Flex>
          <Flex
            // mx={[0, -1, -2]}
            flexWrap="wrap"
            css={[
              PaddedMobile,
              css`
                margin-top: ${rhythm(2)};
              `,
            ]}
          >
            <Box width={1} px={[1, 1, 2]}>
              {posts.edges.length > 0 && (
                <h2 css={GridSectionHeader}>Projects</h2>
              )}
            </Box>
            {posts.edges.map(({ node }) => {
              return (
                <Box
                  width={[1 / 2, 1 / 2, 1 / 4]}
                  p={[1, 1, 2]}
                  key={`box-${node.slug}`}
                  css={GridBoxContainer}
                >
                  <div css={GridBox} key={node.slug}>
                    <Box>
                      <AniLink
                        fade
                        duration={0.6}
                        to={`/${node.slug}`}
                        css={{ textDecoration: `none` }}
                      >
                        {node.featured_media &&
                          node.featured_media.localFile &&
                          node.featured_media.localFile.childImageSharp && (
                            <Img
                              // css={{ marginBottom: rhythm(1) }}
                              key={
                                node.featured_media.localFile.childImageSharp
                                  .fluid.src
                              }
                              fluid={
                                node.featured_media.localFile.childImageSharp
                                  .fluid
                              }
                            />
                          )}
                        <h3 css={GridHeader}>{node.title}</h3>
                      </AniLink>
                    </Box>
                  </div>
                </Box>
              );
            })}
          </Flex>
        </PostMain>
      </Layout>
    );
  }
}

export default CompetencyTemplate;

export const pageQuery = graphql`
  query($id: String!, $slug: String!) {
    wordpressWpCompetency(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        subtitle
      }
    }
    allWordpressPost(
      filter: {
        wordpress_id: { ne: 2320 }
        categories: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          title
          wordpress_id
          excerpt
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
